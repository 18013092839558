<template>
    <b-btn :variant="isEnabled ? 'primary' : 'white'" size="sm" class="btn-language-prop">
        <i @click.prevent="toggle" class="fa-fw fas fa-flag" :title="title"/>
    </b-btn>
</template>

<script>
// btn-language-prop #hot-reload-debug
export default {
    name: `btn-language-prop`,
    props: {
        value: {},
        field: {type: String, required: true}
    },
    computed: {
        target: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        defaultValue() {
            return this.target[this.field];
        },
        isEnabled() {
            return typeof this.target[this.field] === `object`;
        },
        title() {
            if (!this.isEnabled) {
                return this.tr(`Enable Customization by Language|Activer la personnalisation par langue`);
            } else {
                return this.tr(`Disable Customization by Language|Désactiver la personnalisation par langue`)
            }
        }
    },
    methods: {
        toggle() {
            if (!this.isEnabled) {
                let value = this.target[this.field];
                let values = {};
                for (let lang of this.cmsWebsite.settings.languages) {
                    values[lang] = value;
                }
                this.$set(this.target, this.field, values);
            } else {
                this.$set(this.target, this.field, this.target.languageProxy[this.field]);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-language-prop {
    position: relative;
    left: -20px;
    top: 2px;
    border-radius: 20px;
    padding: 1px 7px;
    line-height: 1;
}

.sub-group {
    .btn-language-prop {
        left: -30px;

        &.not-inline,
        &.for-checkbox,
        &.no-label {
            left: -35px;
        }

        &.for-checkbox {
            top: 0;
        }

        &.not-inline {
            top: 35px;
        }
    }
}
</style>
