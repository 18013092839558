<template>
    <div class="input-url form-control">
        <template v-if="!currentUrl">
            <b-btn variant="light-primary" @click="edit()">
                <i class="fas fa-edit mr-1"/>
                {{placeholder|tr}}
            </b-btn>
        </template>

        <template v-else>
            <div class="d-flex">
                <b-img rounded v-if="thumbnail" width="40" height="40" class="mr-2" :src="thumbnail"/>
                <i v-else class="mr-2 fas" :class="glyph"/>
                <div class="overview" style="line-height: 1.2">
                    <div class="type">{{title|tr}}</div>
                    <span class="name f-10" v-if="name">{{name|tr}}</span>
                    <span class="url f-9" :title="currentUrl" v-else>{{currentUrl|tr}}</span>
                </div>

                <b-btn variant="light" class="ml-2 px-2" @click="edit()">
                    <span v-tr>Change|Changer</span>
                </b-btn>

                <b-btn variant="light" class="ml-1 px-2" :disabled="!currentUrl" :title="'Open in new tab|Ouvrir dans un autre onglet'|tr" v-b-tooltip target="_blank" :href="currentUrl">
                    <i class="fas fa-external-link"/>
                </b-btn>
            </div>
        </template>
    </div>
</template>

<script>
import InputFile from "@/components/editor/properties/inputs/input-file.vue";
import LinkPicker from "@/components/editor/properties/inputs/link-picker.vue";

// input-url #hot-reload-debug
export default {
    name: `input-url`,
    components: {LinkPicker, InputFile},
    props: {
        value: {},
        title: {type: String},
        name: {type: String},
        glyph: {type: String, default: `fas-link`},
        thumbnail: {type: String},
        placeholder: {type: String, default: `Add Link|Ajouter un lien`}
    },
    computed: {
        currentUrl: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    methods: {
        edit() {
            this.$emit(`edit`, this.currentUrl);
        }
    }
}
</script>

<style lang="scss" scoped>
.input-url {
    padding: 4px;
    height: 52px;

    .d-flex {
        height: 100%;
        align-items: center;

        .overview {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            height: 100%;
            overflow: hidden;
            font-size: 10pt;
        }

        .type {
            font-weight: 500;
            margin-bottom: 3px;
        }

        .name, .url {
            font-size: 10pt;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
</style>
